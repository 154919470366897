import instance from "../../../config/axios";
import { serverUrl } from "../../../config";
import { getBearerToken, ParseError } from "../../../config/utils";
import { isArray } from "lodash";

export const GetOrganisations = async (
  page = 1,
  offset = 10,
  searchQuery = ""
) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getBearerToken();
  return instance
    .get(
      serverUrl +
        `/admin/organisations?page=${page}&offset=${offset}&search=${searchQuery}`,
      headers
    )
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isArray(response.data) ? response.data : [],
          total: response.total || 0,
          status: 200,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const EditOrganisation = async (data) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getBearerToken();
  return instance
    .put(serverUrl + `/admin/organisations/${data?._id}`, data, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          data: response.data ? response.data : {},
          message: response?.message,
          status: 200,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const CreateOrganisation = async (data) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getBearerToken();
  return instance
    .post(serverUrl + `/admin/organisations/`, data, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          data: response.data ? response.data : {},
          message: response?.message,
          status: 200,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const GenerateAPIKey = async (id) => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getBearerToken();
  return instance
    .get(serverUrl + `/admin/organisations/generate-key/${id}`, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          data: response.data ? response.data : {},
          message: response?.message,
          status: 200,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};

export const GetAllOrganisations = async () => {
  const responseData = {
    loading: false,
    status: 210,
    message: "Something went wrong, Please try again.",
  };
  const headers = await getBearerToken();
  return instance
    .get(serverUrl + `/admin/organisations/all`, headers)
    .then((response) => {
      if (response.data && response.data.code === 200) {
        response = response.data;
        return {
          ...responseData,
          data: isArray(response.data) ? response.data : [],
          total: response.total || 0,
          status: 200,
        };
      } else {
        return {
          ...responseData,
          message: ParseError(response.data),
        };
      }
    })
    .catch((err) => {
      console.log(err.response);
      return {
        ...responseData,
        message: ParseError(
          err.response && err.response.data ? err.response.data : err.message
        ),
      };
    });
};
