import {
  WalletIcon,
  DashboardIcon,
  DashboardBarIcon,
  HistoryBarIcon,
  ActivityLogBarIcon,
  SettingsBarIcon,
  APIKeysIcon,
  CoinBarIcon,
  RtgsBarIcon,
  UserManagementBarIcon,
  ReportIcon,
} from "../../../icons";
import { GenericConstant } from "../../../utils/Constants";
const Coins = {
  mainTitle: "Coin Management",
  title: "Coins",
  classsChange: "mm-collapse",
  to: "/admin/coins",
  roles: [
    GenericConstant._SUPER,
    GenericConstant._SUPERVISOR,
    GenericConstant._ADMIN,
  ],
};
const History = {
  mainTitle: "Coin Management",
  title: "Rolling Reserve",
  classsChange: "mm-collapse",
  to: "/admin/rolling-reserve",
  roles: [GenericConstant._SUPER],
};

const Users = {
  mainTitle: "User Management",
  title: "Users",
  classsChange: "mm-collapse",
  to: "/admin/users",
  roles: [
    GenericConstant._SUPER,
    GenericConstant._SUPERVISOR,
    GenericConstant._ADMIN,
  ],
};
const Admins = {
  mainTitle: "User Management",
  title: "Admins/Supervisors",
  classsChange: "mm-collapse",
  to: "/admin/admins",
  roles: [GenericConstant._SUPER],
};

const UserMenuList = [
  //Dashboard
  {
    title: "Dashboard",
    classsChange: "mm-collapse",
    iconStyle: (
      <DashboardBarIcon className="w-100 h-100" style={{ padding: "1px" }} />
    ),
    to: "dashboard",
  },
  //Fiat Management
  {
    title: "Fiat Management",
    classsChange: "mm-collapse",
    iconStyle: <WalletIcon className="w-100 h-100" />,
    to: "fiat-management",
  },
  //Transaction History
  {
    title: "Transaction History",
    classsChange: "mm-collapse",
    iconStyle: <HistoryBarIcon />,
    to: "transaction-history",
  },
  //Settings
  {
    title: "Settings",
    classsChange: "mm-collapse",
    iconStyle: <SettingsBarIcon />,
    to: "settings",
  },
  //Activity
  {
    title: "Activity",
    classsChange: "mm-collapse",
    iconStyle: <ActivityLogBarIcon />,
    to: "activity",
  },
];

const AdminMenuList = [
  {
    title: "Dashboard",
    classsChange: "mm-collapse",
    iconStyle: <DashboardIcon />,
    to: "/admin/dashboard",
    roles: [
      GenericConstant._SUPER,
      GenericConstant._SUPERVISOR,
      GenericConstant._ADMIN,
    ],
  },
  {
    title: "Fiat Management",
    classsChange: "mm-collapse",
    iconStyle: <WalletIcon />,
    to: "/admin/fiat-management",
    roles: [
      GenericConstant._SUPER,
      GenericConstant._SUPERVISOR,
      GenericConstant._ADMIN,
    ],
  },
  {
    title: "Rtgs/Imt Payments",
    classsChange: "mm-collapse",
    iconStyle: <RtgsBarIcon />,
    to: "/admin/rtgs-imt",
    roles: [
      GenericConstant._SUPER,
      GenericConstant._SUPERVISOR,
      GenericConstant._ADMIN,
    ],
  },
  {
    title: "Transaction History",
    classsChange: "mm-collapse",
    iconStyle: <HistoryBarIcon />,
    to: "/admin/transaction-history",
    roles: [
      GenericConstant._SUPER,
      GenericConstant._SUPERVISOR,
      GenericConstant._ADMIN,
    ],
  },
  {
    title: "Coin Management",
    classsChange: "mm-collapse",
    iconStyle: <CoinBarIcon />,
    to: "/admin/coins",
    content: [Coins, History],
    roles: [
      GenericConstant._SUPER,
      GenericConstant._SUPERVISOR,
      GenericConstant._ADMIN,
    ],
  },
  {
    title: "User Management",
    subTitle: "Users",
    classsChange: "mm-collapse",
    iconStyle: <UserManagementBarIcon />,
    to: "/admin/users",
    content: [Users, Admins],
    roles: [
      GenericConstant._SUPER,
      GenericConstant._SUPERVISOR,
      GenericConstant._ADMIN,
    ],
  },
  {
    title: "Activity",
    classsChange: "mm-collapse",
    iconStyle: <ActivityLogBarIcon />,
    to: "/admin/activity",
    roles: [
      GenericConstant._SUPER,
      GenericConstant._SUPERVISOR,
      GenericConstant._ADMIN,
    ],
  },
  {
    title: "Organisation",
    classsChange: "mm-collapse",
    iconStyle: <APIKeysIcon />,
    to: "/admin/organisation",
    roles: [GenericConstant._SUPER],
  },
  {
    title: "Reports",
    classsChange: "mm-collapse",
    iconStyle: <ReportIcon />,
    to: "/admin/reports",
    roles: [GenericConstant._SUPER],
  },
  {
    title: "Settings",
    classsChange: "mm-collapse",
    iconStyle: <SettingsBarIcon />,
    to: "/admin/settings",
    roles: [
      GenericConstant._SUPER,
      GenericConstant._SUPERVISOR,
      GenericConstant._ADMIN,
    ],
  },
];

const getRoleTabs = (role = "N/A") => {
  return AdminMenuList.reduce((list, tab) => {
    if (tab?.roles?.includes(role) && tab?.content && tab.content.length > 0) {
      let content = tab.content.filter((t) => t?.roles?.includes(role));
      list.push({ ...tab, content });
    } else if (tab.roles.includes(role)) {
      list.push(tab);
    }
    return list;
  }, []);
};
export const MenuList = {
  user: UserMenuList,
  super: getRoleTabs(GenericConstant._SUPER),
  supervisor: getRoleTabs(GenericConstant._SUPERVISOR),
  admin: getRoleTabs(GenericConstant._ADMIN),
};
